import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
const defaultProps = {
  className: null,
};

function LabelGroup({ children, className }) {
  return <div className={classNames(className, 'label-group')}>{children}</div>;
}

LabelGroup.propTypes = propTypes;
LabelGroup.defaultProps = defaultProps;

export default LabelGroup;
