import { uniqueId } from 'lodash-es';
import PropTypes from 'prop-types';
import OverlayTooltip from '@/storychief/components/OverlayTooltip';
import Label from '@/storychief/components/Label';

const propTypes = {
  moreLabels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['label', 'text']),
};
const defaultProps = {
  type: 'label',
};

function LabelMoreIndicator({ type, moreLabels }) {
  function renderMore() {
    if (type === 'label') {
      return <Label>{`+${moreLabels.length}`} more</Label>;
    }

    return <span>{`+ ${moreLabels.length}`}</span>;
  }

  return (
    <OverlayTooltip
      id={uniqueId('label-more-indicator-')}
      content={moreLabels.map((label) => (
        <div key={label.id}>{label.name}</div>
      ))}
    >
      {renderMore()}
    </OverlayTooltip>
  );
}

LabelMoreIndicator.propTypes = propTypes;
LabelMoreIndicator.defaultProps = defaultProps;

export default LabelMoreIndicator;
