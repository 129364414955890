import PropTypes from 'prop-types';
import Label from '@/storychief/components/Label';
import LabelGroup from '@/storychief/components/LabelGroup';
import LabelMoreIndicator from '@/storychief/components/LabelMoreIndicator';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  show: PropTypes.number,
  type: PropTypes.oneOf(['label', 'text']),
};

const defaultProps = {
  show: undefined,
  type: 'text',
};

function LabelListFieldContent({ data, show, type }) {
  if (!data.length) {
    return <span className="text-muted">-</span>;
  }

  function renderLabels() {
    const labels = data.slice(0, show);
    const moreLabels = show !== -1 ? data.slice(show) : [];

    if (type === 'label') {
      return (
        <LabelGroup className="gutter-right-1">
          {labels.map((label) => (
            <Label
              key={label.id}
              variant={label.color ? `color-coding--${label.color}` : undefined}
            >
              {label.is_private && <em className="icon icon-shield gutter-right-1" />}
              {label.name}
            </Label>
          ))}

          {moreLabels.length > 0 ? <LabelMoreIndicator moreLabels={moreLabels} /> : null}
        </LabelGroup>
      );
    }

    return `${labels.map((l) => l.name).join(', ')} `;
  }

  return <div>{renderLabels()}</div>;
}

LabelListFieldContent.propTypes = propTypes;
LabelListFieldContent.defaultProps = defaultProps;

export default LabelListFieldContent;
